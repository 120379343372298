import { initiateCheckout, getForeeCheckoutURL } from "foree-checkout";

export const date_format = "YYYY-MM-DD";
export var agentID = "",
  agent_bw_id = "",
  agent_bw_password = "",
  agent_bw_name = "",
  agent_bw_userId = "",
  agent_promo_id = "",
  logo_url = "",
  white_logo_url = "",
  home_Data = "",
  //TopCities = "",
  //TopReviews = "",
  //Guarantees = "",
  //Amenities = "",
  //TopBlogs = "",
  agentDetails = "",
  ///Carousel = "",
  about_us = "",
  termsAndConditions = "",
  PopUpBanner = "";

export const setTemplateTheme = (data, hostCredientials) => {
  //console.log(data);
  var valid = new Promise((resolve, reject) => {
    if (data.Agent) {
      agentDetails = data.Agent.Rows[0];

      var banner = data.Banner.Rows[0];

      document.documentElement.style.setProperty(
        "--primary-color",
        agentDetails.primary_color
      );
      document.documentElement.style.setProperty(
        "--hover-color",
        agentDetails.secondary_color
      );
      document.documentElement.style.setProperty(
        "--hero-image",
        `url("https://roomph.ascendant.travel/${banner.Image}")`
      );
      document.documentElement.style.setProperty(
        "--hero-mobile-image",
        `url("https://roomph.ascendant.travel/${banner.mobile_image}")`
      );
      home_Data = banner;
      document.title = hostCredientials.agent;
      let link = document.querySelector("link[rel~='icon']");
      link.href = `https://roomph.ascendant.travel/${agentDetails["favicon_url"]}`;
      //console.log(link.href)
      agentID = agentDetails.AgentId;
      agent_bw_id = agentDetails.agent_bw_id;
      agent_bw_password = hostCredientials.password;
      agent_bw_userId = hostCredientials.userId;
      agent_bw_name = agentDetails.agent_bw_name;
      agent_promo_id = hostCredientials.promotionID;
      //TopCities = data["Top Cities"]["Rows"];
      //TopReviews = data["Guest reviews"]["Rows"];
      logo_url = `https://roomph.ascendant.travel/${agentDetails.logo_url}`;
      white_logo_url = `https://roomph.ascendant.travel/${agentDetails.additional_logo_url}`;
      // TopPerformingHotels = data["Top Performing Hotels"]["Rows"];
      about_us = data["About Us"]["Rows"][0]["link"];
      termsAndConditions = data["Terms and Conditions"]["Rows"][0]["link"];
      //
      PopUpBanner = data["Pop-Up Banner"]["RowCount"]
        ? data["Pop-Up Banner"]["Rows"][0]
        : "";

      // Guarantees = data["Guarantees"]["Rows"];
      // Amenities = data["Amenities"]["Rows"];
      //TopBlogs = data["Blogs"] ? data["Blogs"]["Rows"] : "";
      // Carousel = data["Carousel"]["Rows"];

      resolve(data);
    }
  });

  return valid;
};

export const validHotel = (hotelID, id) => {
  var valid = new Promise((resolve, reject) => {
    DealsArray(id).then((value) => {
      if (value.IsFlat == "1") {
        //console.log(value);
        resolve(value);
      } else {
        fetch("/api/accommodation_promotions", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ pid: id }),
        })
          .then((check) => check.json())
          .then((d) => {
            //console.log(d)
            if (d.Status !== "failure") {
              var findHote = d.Collection.Rows.find(
                (r) => r.accommodation_BE_id == Number(hotelID)
              );
              if (findHote) {
                resolve({ ...value, ...findHote });
              } else {
                resolve("");
              }
            } else {
              resolve("");
            }
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      }
    });
  });

  return valid;
};

// export const HomeScreenDetail = (hostCredientials) => {
//   var deals = new Promise((resolve, reject) => {
//     fetch("/api/whitelabels_api", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(hostCredientials),
//     })
//       .then((createAbRes) => createAbRes.json())
//       .then((homeDatares) => {
//         // console.log(homeDatares)
//         resolve(homeDatares);
//       })
//       .catch((err) => {
//         console.log(err);
//         reject(err);
//       });
//   });
//   return deals;
// };

export const DealsArray = (id) => {
  var deals = new Promise((resolve, reject) => {
    // console.log(agent_promo_id)
    fetch("/api/promotions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ agent_promo_id: agent_promo_id }),
    })
      .then((r) => r.json())
      .then((d) => {
        //console.log(d);
        if (d.Status !== "failure") {
          var dd = d.Collection.Rows;

          if (id) {
            resolve(dd.find((s) => s.id == id));
          } else {
            var sorterArray = new Array();
            for (let i = 0; i < dd.length; i++) {
              var check =
                sorterArray.length !== 0
                  ? sorterArray.findIndex(
                      (s) =>
                        s.promotion_type.trim() == dd[i].promotion_type.trim()
                    )
                  : -1;
              //console.log(check);
              if (check !== -1) {
                sorterArray[check].PromoSlider.push(dd[i]);
              } else {
                sorterArray.push({
                  promotion_type: dd[i].promotion_type,
                  PromoSlider: [dd[i]],
                });
              }
            }
            resolve(sorterArray);
          }
        } else {
          resolve([]);
        }
        //console.log(dd);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
  return deals;
};

export const destination_deals = (id) => {
  var deals = new Promise((resolve, reject) => {
    // console.log(agent_promo_id)
    fetch("/api/promotions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ agent_promo_id: agent_promo_id }),
    })
      .then((r) => r.json())
      .then((d) => {
        //console.log(d);
        if (d.Status == "success") {
          var val = d.Collection.Rows.find((r) => r.IsFlat == 1);
          resolve(val ? val : "");
        } else {
          resolve("");
        }
        //console.log(dd);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
  return deals;
};

export function percentageCalculator(price, percentage) {
  return Number((parseInt(percentage) * parseInt(price)) / 100);
}
export function percentagePriceCalculator(price, percentage) {
  //console.log(price, percentage);
  return Number(
    Number(price) - Number((parseInt(percentage) * parseInt(price)) / 100)
  );
}

export const fetchReviews = (id) => {
  var deals = new Promise((resolve, reject) => {
    fetch("/api/hotelReviews", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ postHotelId: id }),
    })
      .then((data) => data.json())
      .then((reviews) => {
        resolve(reviews);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
  return deals;
};

export const generateRandomNDigits = (n) => {
  var minm = 10000;
  var maxm = 99999;
  return Math.floor(Math.random() * (maxm - minm + 1)) + minm;
};
export const sendOTP = (customerPay) => {
  var sendOTP = new Promise((resolve, reject) => {
    fetch("/api/sendOTP", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(customerPay),
    })
      .then((data) => data.json())
      .then((sendOTPRes) => {
        resolve(sendOTPRes);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
  return sendOTP;
};

export const CurrencyConversionFn = () => {
  var currencyCon = new Promise((resolve, reject) => {
    fetch("/api/currencyCon")
      .then((Rdata) => {
        if (!Rdata.ok) {
          throw Error("could not fetch the data for that resorce");
        }
        return Rdata.json();
      })
      .then((detail) => {
        resolve(
          detail["Success"]["Result"].find(
            (d) =>
              d["ToCurrencyCode"] == "PKR" && d["FromCurrencyCode"] == "USD"
          )
        );
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
  return currencyCon;
};

export const fetchHotelDetail = (hotelParams) => {
  var deals = new Promise((resolve, reject) => {
    fetch("/api/hotelDetails", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        postHotelId: hotelParams.id,
        agent_bw_userId: agent_bw_userId,
        agent_bw_password: agent_bw_password,
      }),
    })
      .then((Rdata) => {
        if (!Rdata.ok) {
          throw Error("could not fetch the data for that resorce");
        }
        return Rdata.json();
      })
      .then(async (detail) => {
        //console.log(detail);
        var resultHotels = detail["getHotels"]
          ? detail["getHotels"]["Success"][0]["Result"][0]
          : "";
        if (resultHotels) {
          const searchedHotel = await fetchAllHotel({
            ...hotelParams,
            accommodationname: resultHotels["AccommodationName"][0],
          });
          if (searchedHotel) {
            resultHotels["MinRate"] = searchedHotel["MinRate"];
          } else {
            resultHotels["MinRate"] = [];
          }
          // console.log(resultHotels);
          //MinRate
        }
        resolve(resultHotels);
      });
  });
  return deals;
};

export const fetchAllHotel = (hotelParams) => {
  var deals = new Promise((resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        postCity: hotelParams.city,
        postCheckIn: hotelParams.checkin,
        postCheckOut: hotelParams.checkout,
        postAdults: "",
        postRooms: "",
        postPriceStart: "",
        postPriceEnd: "",
        postRating: "",
        postPremium: "",
        postCategory: "",
        userRating: "",
        agent_bw_password: agent_bw_password,
        agent_bw_id: agent_bw_id,
        agent_bw_userId: agent_bw_userId,
        accommodationname: hotelParams.accommodationname
          ? hotelParams.accommodationname
          : "",
      }),
    };

    fetch("/api/world", requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw Error("could not fetch the data for that resorce");
        }
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        if (data.Success) {
          // const searchedHotel = data.Success.result.find(
          //   (h) => h.AccommodationId == hotelParams.id
          // );
          resolve(data["Success"]["result"][0]);
        } else {
          resolve("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  });
  return deals;
};

export const fetchPastBooking = (id, hotelId) => {
  var deals = new Promise((resolve, reject) => {
    fetch("/api/bookings", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ postProfileId: id }),
    })
      .then((res) => res.json())
      .then((pastBooking) => {
        if (pastBooking.getHotels) {
          pastBooking.getHotels.Success[0].Result.map((book) => {
            if (book.AccommodationID == hotelId) {
              resolve(true);
              return;
            }
          });
        } else {
          resolve(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  });
  return deals;
};

export const createBooking = (
  comments,
  contactInfo,
  hotelParams,
  finalPrice,
  roomId,
  ratePlanId,
  extraids,
  guest_qtys,
  Total,
  ratesbydate,
  roomqty,
  isChecked,
  ProfileId
) => {
  //console.log(comments)
  var deals = new Promise((resolve, reject) => {
    fetch("/api/book", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...contactInfo,
        comments: comments,
        name: contactInfo.fullname.split(" ")[0],
        lastName: contactInfo.fullname.split(" ")[1],
        postHotelId: hotelParams.id,
        check_in: hotelParams.checkin,
        check_out: hotelParams.checkout,
        Total: Number(Total + finalPrice),
        roomId: roomId,
        ratePlanId: ratePlanId,
        extraids: extraids,
        guest_qtys: guest_qtys,
        ratesbydate: ratesbydate,
        ProfileId: ProfileId,
        roomqty: roomqty,
        cc_type: isChecked ? 18 : 36,
        bookingstatus: isChecked ? 1 : 5,
        agent_bw_password: agent_bw_password,
        agent_bw_id: agent_bw_id,
        agent_bw_userId: agent_bw_userId,
        agent_bw_name: agent_bw_name,
        front_promotion_code: "",
        front_promotion_Type: "",
        front_promotion_Value: "",
      }),
    })
      .then((data) => data.json())
      .then((BookingData) => {
        //console.log(BookingData)
        resolve(BookingData.ReservationCreate.Success[0].Result[0]);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
  return deals;
};

export const RoomphBooking = (
  BookingData,
  hotelParams,
  Jsoncode,
  finalPrice,
  contactInfo
) => {
  var roomphBooking = new Promise((resolve, reject) => {
    fetch("/api/Create_Booking", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Ambassadorid: Jsoncode.Collection.Rows[0].ambassador_id,
        Hotelid: hotelParams.id,
        bookingNo: BookingData.BookingID[0],
        pinCode: contactInfo.promo,
        Status: "pipeline",
        checkIn: hotelParams.checkin,
        checkOut: hotelParams.checkin,
        cancellationPolicy: "null",
        Refundable: "null",
        prePaid: "null",
        postPaid: "null",
        Commission: percentageCalculator(
          finalPrice,
          Jsoncode.Collection.Rows[0].commission_percentage
        ),
        Profile_Id: Jsoncode.Collection.Rows[0].profile_id,
      }),
    })
      .then((Abook) => {
        if (!Abook.ok) {
          throw Error("could not fetch the data for that resorce");
        }
        return Abook.json();
      })
      .then((JsonAbook) => {
        resolve(JsonAbook);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
  return roomphBooking;
};

export const validateAmbassadorCode = (contactInfo) => {
  const ambasadorValidation = new Promise((resolve, reject) => {
    fetch("/api/validateAmbassadorCode", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ code: contactInfo.AmbasoodarCode }),
    })
      .then((promo) => {
        if (!promo.ok) {
          throw Error("could not fetch the data for that resorce");
        }
        return promo.json();
      })
      .then((Jsoncode) => {
        resolve(Jsoncode);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
  return ambasadorValidation;
};

export function initiateMyCheckout(
  hotelID,
  BookingData,
  totalPrice,
  email,
  mobileNumber,
  promoArray
) {
  //console.log(hotelID, BookingData, totalPrice, email, mobileNumber, promoArray)
  let URL = getForeeCheckoutURL();
  var foreeDEtail = {
    key: "ec45ffa0-f178-49d8-a400-829650b9a7d7",
    amount: totalPrice,
    create_bill: true,
    reference_number: BookingData.BookingID,
    callback: callback,
    customer_email_address: email,
    customer_phone_number: mobileNumber,
    bill_details_id: BookingData.BookingID,
  };
  initiateCheckout(foreeDEtail, false);
  window.onmessage = (e) => {
    if (e.origin !== URL) return;
    if (e.data.status == "2") {
      //failure
      fetch("/api/update_book", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          accommodationid: hotelID,
          bookingid: BookingData.BookingID,
          pincode: BookingData.PinCode,
          status: 9,
        }),
      })
        .then((data) => data.json())
        .then((canceldata) => {
          //console.log(canceldata);
        });
    } else {
      fetch("/api/update_book", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          accommodationid: hotelID,
          bookingid: BookingData.BookingID,
          pincode: BookingData.PinCode,
          status: 1,
        }),
      })
        .then((data) => data.json())
        .then((canceldata) => {
          //console.log(canceldata);
        });
    }
  };
}

function callback(param) {
  window.onmessage = (e) => {
    if (e.origin !== URL) return;
    console.log(e.data);
  };
  // var hotelId = $('#hotel_id').val();
  // var status = param.status;
  // var referenceNumber = $('#referencenumber').val();
  // var bookingNo = $('#bookingno').val();
  // var sessionId = $('#sid').val();
  // var checkin = $('.checkin').val();
  // var checkout = $('.checkout').val();
  // var domain = $('.domain').val();
  // var device = $('.device').val();
  // var promocode = $('#promocode').val();
  // var promoid = $('#promoid').val();
  // var promotionDiscount = $('#promotionDiscount').val();
  // $('#paymentresponse').val(JSON.stringify(param));
  // $.ajax({
  //     url: "https://www.roomph.pk/book/functions/bookingupdate.php",
  //     type: 'POST',
  //     data: {
  //         'hotel_Id': hotelId,
  //         'status': status,
  //         'bookingNo': bookingNo,
  //         'paymentresponse': param,
  //         'sessionId': sessionId,
  //         'checkin': checkin,
  //         'checkout': checkout,
  //         'domain': domain,
  //         'device': device,
  //         'promocode': promocode,
  //         'promoid': promoid,
  //         'promotionDiscount': promotionDiscount
  //     },
  //     beforeSend: function() {

  //     },
  //     success: function(response) {
  //         var Data = JSON.parse(response);
  //         if (param.status == 1) {
  //             Data.url
  //             window.location = '' + Data.url;
  //         }
  //         if (param.status == 2) {
  //             $('#loading').hide();
  //         }

  //     }
  // });
}

// get dates between two functions
export function getDatesBetween(startDate, endDate) {
  let dates = [];
  let currentDate = new Date(startDate);
  const lastDate = new Date(endDate);

  // Increment the date until it reaches the end date
  while (currentDate < lastDate) {
    // Add the current date to the array
    dates.push(new Date(currentDate));
    // Move to the next day
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates.map((date) => date.toISOString().split("T")[0]);
}

export function CalculateDiscountByRates(
  ratePlan,
  discount,
  room,
  datesBetween,
  CurrencyRates
) {
  //console.log(room, ratePlan, datesBetween);
  let ratesList = [];
  datesBetween.map((d, i) => {
    //.log(d);
    let discountedRateByDate = discount
      ? discount.find((item) => item.date == d)
      : null;

    //console.log("discountedRateByDate=>", discountedRateByDate);
    let dateRatePlan = discountedRateByDate
      ? discountedRateByDate["rate_plans"]
      : [];


    //console.log(discountedRateByDate);
    let checkAvailability = dateRatePlan.length
      ? dateRatePlan.find(
          (r) =>
           // console.log(r.accommodation_room_id.toLowerCase(), r.accommodation_rateplan_id.toLowerCase(), room.toLowerCase(), ratePlan.RatePlanName[0].toLowerCase())
            r.accommodation_room_id.toLowerCase() === room.toLowerCase() &&
            ratePlan.RatePlanName[0].toLowerCase().includes(  r.accommodation_rateplan_id.toLowerCase())
        )
      : null;

   // console.log("check availability => ", checkAvailability);
    // let dRoomIndex = dateRatePlan.length?discountedRateByDate
    //   ? discountedRateByDate["accommodation_room_id"].indexOf(
    //       room.toLowerCase()
    //     )
    //   : -1
    // let dRoomRatePlanIndex = discountedRateByDate
    //   ? discountedRateByDate["accommodation_rateplan_id"].indexOf(
    //       ratePlan.RatePlanName[0].toLowerCase()
    //     )
    //   : -1;

    var ratesByDate = ratePlan.RateDetailsByDate[0].RatePerDate[0].$.Rate;
    let Total_check = checkAvailability ? true : false;
    //console.log(Total_check);

    let finalRate =
      ratePlan["CurrencyCode"][0] == "USD"
        ? CurrencyRates["ConversionRate"][0] * ratesByDate
        : ratesByDate;
    let FinalAmount = finalRate;
    //console.log(discount[0]['discount_percentage'][dRoomRatePlanIndex], FinalAmount);
    if (discount && Total_check) {
      FinalAmount = checkAvailability
        ? checkAvailability.accommodation_discountType == "Percentage"
          ? percentagePriceCalculator(
              finalRate,
              checkAvailability["discount_percentage"]
            )
          : checkAvailability.special_rate
        : finalRate;

      FinalAmount = Math.round(FinalAmount);
      ratesList.push({
        Room: room,
        date: d,
        isDiscount: Total_check,
        ratePlanName: ratePlan.RatePlanName[0],
        discount_percentage: checkAvailability["discount_percentage"],
        FinalAmount: FinalAmount,
        finalRate: finalRate,
        ratesByDate: ratesByDate,
        special_rate: checkAvailability["special_rate"],
      });
    } else {
      FinalAmount = Math.round(FinalAmount);
      ratesList.push({
        Room: room,
        date: d,
        isDiscount: Total_check,
        ratePlanName: ratePlan.RatePlanName[0],
        FinalAmount: FinalAmount,
        finalRate: finalRate,
        ratesByDate: ratesByDate,
      });
    }
    //}
  });
  return ratesList;
}
