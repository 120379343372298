import { agent_bw_name } from "./components/BasicComponents/BasicFunctionScript";

const fuser = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user")).result.result.data[0]
  : "";
//console.log(fuser);

export const page_view = (
  type,
  destination,
  name,
  hoteltype,
  id,
  price,
  room,
  days,
  adults,
  star,
  userrating,
  discount
) => {
  window.dataLayer.push({
    event: "page_view",
    ecommerce: {
      detail: {
        actionField: { page_type: type },
        products: {
          agent: agent_bw_name,
          language: navigator.language,
          hotel_location: destination,
          hotel_name: name,
          hotel_id: id,
          hotel_category: hoteltype,
          product_price: price,
          rooms_quantity: room,
          days_quantity: days,
          persons_quantity: adults,
          star_ratings: star,
          review_ratings: userrating,
          discountId: discount,
        },
      },
    },
  });
};

export const click_topdestination = (topcity) => {
  //console.log(topcity)
  window.dataLayer.push({
    event: "click_topdestination",
    ecommerce: {
      detail: {
        actionField: { page_type: "Home" }, // 'detail' actions have an optional list property.
        products: {
          page_section: "fold_2",
          language: navigator.language,
          hotel_location: topcity,
          agent: agent_bw_name,
          city:topcity
        },
      },
    },
  });
};


export const featuredHotel = (hotelname, id, city, type, price) => {
  window.dataLayer.push({
    event: "click_featuredhotel",
    ecommerce: {
      detail: {
        actionField: { page_type: "Home" }, // 'detail' actions have an optional list property.
        products: {
          page_section: "fold_3",
          agent: agent_bw_name,
          language: navigator.language,
          hotel_name: hotelname,
          hotel_id: id,
          hotel_location: city,
          hotel_category: type,
        },
      },
    },
  });
};

export const click_serach_destination = (
  rooms,
  adults,
  child,
  check_in,
  check_out,
  city
) => {
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "click_search",
    ecommerce: {
      detail: {
        actionField: { page_type: "Home" }, // 'detail' actions have an optional list property.
        products: {
          page_section: "fold_1",
          agent: agent_bw_name,
          language: navigator.language,
          filter_name: ["room", "child", "adults", "check_in", "check_out"],
          filter_value: [rooms, child, adults, check_in, check_out],
          hotel_location: city,
        },
      },
    },
  });
};

// destination page GTM

export const property_book = (
  name,
  id,
  address,
  type,
  price,
  room,
  days,
  star,
  review,
  adults,
  child
) => {
  window.dataLayer.push({ ecommerce: null });

  window.dataLayer.push({
    event: "click_reservenow",
    ecommerce: {
      detail: {
        actionField: { page_type: "Destination" }, // 'detail' actions have an optional list property.
        products: {
          page_section: "Product_List",
          language: navigator.language,
          hotel_name: name,
          hotel_id: id,
          hotel_location: address,
          hotel_category: type,
          product_price: price,
          rooms_quantity: room,
          days_quantity: days,
          persons_quantity: Number(adults),
          star_ratings: star,
          review_ratings: review,
          agent: agent_bw_name,
        },
      },
    },
  });
};

// hotels page GTM
export const reserve_now = (
  name,
  id,
  address,
  type,
  price,
  room,
  days,
  star,
  review,
  adults,
  roomType
) => {
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "reserve_now",
    ecommerce: {
      detail: {
        actionField: { page_type: "hotel_property" }, // 'detail' actions have an optional list property.
        products: {
          page_section: "booking_details",
          agent: agent_bw_name,
          language: navigator.language,
          hotel_name: name,
          hotel_id: id,
          hotel_location: address,
          hotel_category: type,
          cart_price: price,
          product_price: price,
          rooms_quantity: room,
          days_quantity: days,
          persons_quantity: adults,
          room_types: roomType,
          star_ratings: star,
          review_ratings: review,
        },
      },
    },
  });
};

// b0oking page GTM Tracking
export function bookeddata(
  name,
  id,
  address,
  type,
  pricewithtax,
  price,
  room,
  days,
  adults,
  roomselectType,
  star,
  review,
  discountId,
  discount_percentage
) {
  //console.log(roomselectType)
  window.dataLayer.push({
    event: "book_now",
    ecommerce: {
      detail: {
        actionField: { page_type: "Booking" }, // 'detail' actions have an optional list property.
        products: {
          page_section: "booking_confirmation",
          agent: agent_bw_name,
          language: navigator.language,
          hotel_name: name,
          hotel_id: id,
          hotel_location: address,
          hotel_category: type,
          cart_price: pricewithtax,
          product_price: price,
          rooms_quantity: room,
          days_quantity: days,
          persons_quantity: adults,
          room_types: roomselectType,
          star_ratings: star,
          review_ratings: review,
          discountId: discountId,
          discount_percentage: discount_percentage,
        },
      },
    },
  });
}

// thankyou page GTM tracking

export const booking_confirmed = (
  name,
  id,
  address,
  type,
  pricewithtax,
  price,
  room,
  days,
  adults,
  roomselectType,
  star,
  review,
  discountId,
  discount_percentage
) => {
  window.dataLayer.push({
    event: "booking_confirmed",
    ecommerce: {
      detail: {
        actionField: { page_type: "thankyou_page" }, // 'detail' actions have an optional list property.
        products: {
          page_section: "thank_you",
          agent: agent_bw_name,
          language: navigator.language,
          hotel_name: name,
          currency: "PKR",
          hotel_id: id,
          hotel_location: address,
          hotel_category: type,
          product_price: price,
          rooms_quantity: pricewithtax,
          days_quantity: days,
          cart_price: pricewithtax,
          persons_quantity: adults,
          room_types: roomselectType,
          payment_method: "cash on counter", //'Through card'
          star_ratings: star,
          review_ratings: review,
          cancellation_policy: "",
          discountId: discountId,
          discount_percentage: discount_percentage,
        },
      },
    },
  });
};

// deals GTM tracking
export const click_view_deals = (page_type) => {
  window.dataLayer.push({
    event: "click_deals_button",
    ecommerce: {
      detail: {
        actionField: { page_type: page_type }, // 'detail' actions have an optional list property.
        products: {
          agent: agent_bw_name,
          language: navigator.language,
        },
      },
    },
  });
};

export const select_deals = (
  dealsname,
  id,
  discount_percentage,
  valid_till,
  valid_from
) => {
  //ecommerce.detail.products.deal

  window.dataLayer.push({
    event: "select_deals",
    ecommerce: {
      detail: {
        actionField: { page_type: "Deals" }, // 'detail' actions have an optional list property.
        products: {
          page_section: "Deals_detail",
          agent: agent_bw_name,
          language: navigator.language,
          discountId: id,
          deal: dealsname,
          discount_percentage: discount_percentage,
          valid_till: valid_till,
          valid_from: valid_from,
        },
      },
    },
  });
};

export const click_deals_property = (
  id,
  discount_percentage,
  valid_till,
  valid_from,
  accommodation_BE_id,
  accommodation_name
) => {
  //ecommerce.detail.products.accommodation_name
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "click_deals_property",
    ecommerce: {
      detail: {
        actionField: { page_type: "Deals" }, // 'detail' actions have an optional list property.
        products: {
          page_section: "Deals_detail",
          agent: agent_bw_name,
          language: navigator.language,
          discountId: id,
          discount_percentage: discount_percentage,
          valid_till: valid_till,
          valid_from: valid_from,
          accommodation_BE_id: accommodation_BE_id,
          accommodation_name: accommodation_name,
        },
      },
    },
  });
};

// whatsapp icon click
export const whatsapp_click = () => {
  //ecommerce.detail.products.accommodation_name
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "whatsapp_click",
    ecommerce: {
      detail: {
        actionField: { page_type: "Deals" }, // 'detail' actions have an optional list property.
        products: {
          page_section: "Deals_detail",
          agent: agent_bw_name,
          language: navigator.language,
        },
      },
    },
  });
};

// footer and basic click events
export const basic_click_events = (event, page_type) => {
  //ecommerce.detail.products.accommodation_name
  window.dataLayer.push({
    event: event,
    ecommerce: {
      detail: {
        actionField: { page_type: page_type }, // 'detail' actions have an optional list property.
        products: {
          agent: agent_bw_name,
          language: navigator.language,
        },
      },
    },
  });
};
