import React from "react";
import { useMemo } from "react";
import { Marker, InfoWindow } from "@react-google-maps/api";
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '100%'
};


function GoogleMapContainer(props) {
    console.log(props)
    var markers = new Array();
    if (props.data.length > 0) {
        props.data.map((mar, index) => {
            markers.push({
                lat: Number(mar.Latitude),
                lng: Number(mar.Longitude),
                LatLng: [{
                    lat: Number(mar.Latitude),
                    lng: Number(mar.Longitude)
                }],
                rate: mar.MinRate[0]
            })
        })
    } else {
        console.log(markers);
    }

    const center = {
        lat: Number(markers[0].lat),
        lng: Number(markers[0].lng)
    }
    const options = {
        center: center,
        streetViewControl: false,
        mapTypeControl: false,
        styles: [
            {
                featureType: "poi",
                elementType: "labels",
                stylers: [{ visibility: "off" }]
            }
        ]
    }

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBBnY9OgxDfjePVgEPsAasPirQSDCtFUa4"
    })

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        //const bounds = new window.google.maps.LatLng(center.lat, center.lng);
        map.setZoom(12);
        // map.fitBounds(bounds);
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])


    return isLoaded ? (
        <GoogleMap
            options={options}
            mapContainerStyle={containerStyle}
            center={center}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            { /* Child components, such as markers, info windows, etc. */}
            {
                markers.map((mar, index) => {
                    var position = {
                        lat: mar.lat,
                        lng: mar.lng
                    }
                    return (
                        <Marker
                            raiseOnDrag={true}
                            visible={true} // or false. Whatever you need.
                            draggable={false}
                            key={index}
                            position={position}
                            icon={'https://www.roomph.pk/v1/images/marker-icon.png'}
                            animation={window.google.maps.Animation.DROP}
                        >

                            <InfoWindow
                                id={index}>
                                <div key={index}>
                                    <p>{mar.rate}</p>
                                </div>
                            </InfoWindow>
                        </Marker>

                    )
                })
            }


        </GoogleMap >
    ) : <></>
}

export default React.memo(GoogleMapContainer)
