import React, { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import { FaDownload } from "react-icons/fa6";
import Skeleton from "react-loading-skeleton";

import { useSession } from "../../SessionProvider";
import BlankPage from "../BasicComponents/BlankPage";
import Footer from "../footer/Footer";
import history from "../history";
import Topbar from "../Topbar/Topbar";

export const Booking = () => {
  const [hoteldetails, sethoteldetails] = useState("");
  const [loading, setloading] = useState(false);
  const { user, signOut } = useSession();
  // console.log(user);

  useEffect(() => {
    if (user.id) {
      //console.log(userDetails.result.result.data[0].profile_id[0]);
      fetch("/api/bookings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          postProfileId: user.id,
        }),
      })
        .then((res) => res.json())
        .then((pastBooking) => {
          // console.log(pastBooking)
          sethoteldetails(
            pastBooking.getHotels.Success[0].Result.sort((a, b) => {
              return (
                moment(b.ArrivalDate[0], "YYYY-MM-DD") -
                moment(a.ArrivalDate[0], "YYYY-MM-DD")
              );
            })
          );
          setloading(true);
        })
        .catch((err) => {
          console.log(err);
          sethoteldetails("");
          setloading(true);
        });
    } else {
      console.log("no users found");
    }
  }, [user]);

  return (
    <section>
      <Topbar color="#FF3D00" page="property" />
      <section className="container deals-cc">
        <div className="deals-container">
          <div className="main-heading text-center py-4">My Booking</div>
        </div>
      </section>
      <div className="container mt-5 mb-5 past_bookings min-h-100">
        {loading ? (
          hoteldetails ? (
            <div className="grid-top">
              {hoteldetails.map((h, i) => {
                return (
                  <div className="grid" key={i}>
                    <div className="grid-item-1">
                      <img src={h.ImageURL} alt="" />
                    </div>
                    <div className="grid-item-2">
                      <div className="sub-heading line-height9 mt-1">
                        {h.AccommodationName[0]}
                      </div>
                      <div className="mb-1">
                        {Array.apply(null, {
                          length: h.Rating[0] ? Number(h.Rating[0]) : 0,
                        })
                          .map(Number.call, Number)
                          .map((item) => {
                            return (
                              <img
                                key={item}
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/Asset100.svg"
                                }
                                className="bookingstar"
                                alt=""
                              />
                            );
                          })}
                      </div>
                      <div className="d-flex align-item-center">
                        <div className="ststus mb-3">{h.BookingStatus[0]}</div>
                        <a
                          href={`http://beapi.bookingwhizz.com/Booking/hotelconfirmationPrint.aspx?id=${h.BookingID[0]}&pin=${h.PinCode[0]}`}
                          target="_blank"
                        >
                          <FaDownload className="f-23 color-p pointer ml-1 mb-1" />
                        </a>
                      </div>
                      <p className="mb-1 bookingtext">
                        <b className="text-orange">Stay: </b>
                        {h.ArrivalDate[0]} - {h.DepartureDate[0]}
                      </p>
                      <p className="mb-1 bookingtext">
                        <b className="text-orange">Price: </b>
                        {h.TotalPrice[0]}
                      </p>

                      <p className="mb-1 bookingtext">
                        <b className="text-orange">Booking ID: </b>
                        {h.BookingID[0]}
                      </p>
                      <p className="mb-1 bookingtext">
                        <b className="text-orange">Pin Code: </b>
                        {h.PinCode[0]}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <BlankPage />
          )
        ) : (
          <div className="grid-top">
            <LoadingSkeleton />
            <LoadingSkeleton />
            <LoadingSkeleton />
            <LoadingSkeleton />
            <LoadingSkeleton />
            <LoadingSkeleton />
          </div>
        )}
      </div>

      <Footer />
    </section>
  );
};
export const LoadingSkeleton = () => {
  return (
    <div className="grid">
      <div className="grid-item-1">
        <Skeleton width="100%" height="100%" />
      </div>
      <div className="grid-item-2">
        <div className="mb-1">
          <Skeleton width="25em" height="1em" />
        </div>

        <div className="mb-3">
          <Skeleton width="10em" />
        </div>
        <p className="mb-1">
          <Skeleton width="19em" />
        </p>
        <p className="mb-1">
          <Skeleton width="17em" />
        </p>
        <p className="mb-1">
          <Skeleton width="16em" />
        </p>
        <p className="mb-1">
          <Skeleton width="15em" />
        </p>
      </div>
    </div>
  );
};
