import React, {useState}from 'react'
function BlankPage() {

    return (
        <section>
            <div className="blank-content deals-back d-flex align-items-center justify-content-center">
                <img src='images/norecordfound.png' style={{width:'25rem', height:'25rem'}}></img>
       
            </div>
        </section>

    )
}

export default BlankPage
